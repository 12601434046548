@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Set heroicon's stroke width */
.heroicon-sw-1 path {
  stroke-width: 1 !important;
}
iframe {
  pointer-events: none;
}
.important-whitespace-normal {
  white-space: normal !important;
}
::-webkit-calendar-picker-indicator {
  margin-left: -5px;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0) scale(1);
  }
  2% {
    transform: translateX(0) scale(0.8);
  }
  5% {
    transform: translateX(-60px) scale(0.8);
  }
  7% {
    transform: translateX(-60px) scale(1);
  }

  50% {
    transform: translateX(-60px) scale(1);
  }
  52% {
    transform: translateX(-60px) scale(0.8);
  }
  55% {
    transform: translateX(0) scale(0.8);
  }
  60% {
    transform: translateX(0) scale(1);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

.horizontal-shaking {
  animation: horizontal-shaking 5s infinite;
}
